import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { appInstanceId } from "../../App";

export const ChatContext = createContext(null);

export function ChatProvider({ children, eventBus }) {
  const [unreadCount, setUnreadCount] = useState({});
  const [channels, setChannels] = useState({});
  const [activeChannel, setActiveChannel] = useState("");

  /**
   * update the unread count for a given channel
   */
  const updateUnread = useCallback(
    (channel, count) =>
      setUnreadCount(u => ({
        ...u,
        [channel]: count,
      })),
    [setUnreadCount],
  );

  /**
   * Put a new message into a channel
   */
  const addMessage = useCallback(
    (channel, message) => {
      const currentMessages = channels[channel] || [];
      const currentUnread = unreadCount[channel] || 0;
      if (channel !== activeChannel) {
        updateUnread(channel, currentUnread + 1);
      }
      setChannels({
        ...channels,
        [channel]: [...currentMessages, message],
      });
    },
    [channels, activeChannel, unreadCount, updateUnread],
  );

  /**
   * Clean messages of a channel
   */
  const cleanMessages = useCallback(
    channel => {
      setChannels({
        ...channels,
        [channel]: [],
      });
    },
    [channels],
  );

  /**
   * return all messages for a channel
   */
  const getMessages = channel => channels[channel] || [];

  /**
   * return the current unread count for a channel
   */
  const getUnreadCount = channel => unreadCount[channel] || 0;

  const isActiveChannel = useCallback(
    channel =>
      activeChannel !== "" && channel !== "" && channel === activeChannel,
    [activeChannel],
  );

  /**
   *
   * @param message
   */
  const normalize = event => {
    // no channel exists
    if (event.participant) {
      return {
        ...event,
        channel: event.message.channel ? event.message.channel : appInstanceId,
        from: event.participant.identity,
        message: event.message.message,
      };
    }

    return event;
  };

  const hasActiveChannel = () => activeChannel !== "";

  /**
   * Listen to the eventbus for chat events. Events look like
   * ```
   * {
   *    channel: "channelName",
   *    from: "displayName",
   *    message: "Chat Message Text"
   * }
   */
  useEffect(() => {
    const handler = event => {
      event = normalize(event);
      addMessage(event.channel, {
        self: event.self,
        from: event.from,
        message: event.message,
        timestamp: new Date(),
      });
    };

    eventBus.on("chat", handler);

    return () => {
      eventBus.off("chat", handler);
    };
  }, [addMessage, eventBus]);

  /**
   * Clear the unread count of the channel when it becomes active
   */
  useEffect(() => {
    updateUnread(activeChannel, 0);
  }, [activeChannel, updateUnread]);

  return (
    <ChatContext.Provider
      value={{
        addMessage,
        cleanMessages,
        getMessages,
        getUnreadCount,
        updateUnread,
        activeChannel,
        setActiveChannel,
        isActiveChannel,
        hasActiveChannel,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChatContext() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within an ChatProvider");
  }

  return context;
}
